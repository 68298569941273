import { BackButton } from "@/components/Buttons/BackButton";
import type { CarouselApi } from "@/components/Carousel";
import { useEffect, useState } from "react";
import { HowToPlayComponent } from "./Tutorial";

export const HowToPlayPage = () => {
  const [api, setApi] = useState<CarouselApi>();
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    if (!api) {
      return;
    }

    api.on("select", () => {
      setCurrentStep(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  return (
    <div className="p-2.5 pb-24 text-white flex flex-col">
      <div className="flex justify-center relative -mx-2.5">
        <BackButton className="absolute left-0 cursor-pointer" />
        <p className="text-white">How to Play</p>
      </div>

      <HowToPlayComponent
        className="mt-5 font-koulen w-full flex flex-col justify-center items-center"
        carouselClassName="w-11/12"
        autoPlay={true}
        api={api}
        setApi={setApi}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
      />

      <ul className="mt-[30px] px-2.5 list-decimal text-icon text-xs">
        <li className="mt-4">Choose a round you want to join and proceed to player selection.</li>
        <li className="mt-4">Select 5 or 11 players by choosing A or B based on scoring potential.</li>
        <li className="mt-4">Assign a Captain (2x score) and/or Vice-Captain to complete your team.</li>
        <li className="mt-4">Select an entry fee and join the tournament.</li>
        <li className="mt-4">Compete against other users on real match results and earn rewards!</li>
      </ul>
    </div>
  );
};
