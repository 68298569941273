import { create } from "zustand";
import { LEAGUES, type League } from "./pages/Home/constants";

export interface IHomePageStore {
  selectedLeague: League;
  setSelectedLeague: (league: League) => void;
}

export const useHomePageStore = create<IHomePageStore>((set) => ({
  selectedLeague: LEAGUES[0],
  setSelectedLeague: (league: League) => set({ selectedLeague: league }),
}));
