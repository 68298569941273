import { cn } from "@/utils";
import Autoplay from "embla-carousel-autoplay";
import { useEffect, useMemo, useState } from "react";
import { Fragment } from "react/jsx-runtime";

import { PrimaryButton } from "@/components/Buttons/PrimaryButton";
import { Carousel, type CarouselApi, CarouselContent, CarouselItem } from "@/components/Carousel";
import { useNavigate } from "react-router-dom";

type Step = {
  id: number;
  title: string;
  image: string;
  message: string;
};

const steps: Step[] = [
  {
    id: 1,
    title: "Pick a Player",
    image: "/tutorial/step-1.png",
    message: "Choose a player which will play\nbetter in the match",
  },
  {
    id: 2,
    title: "Assemble a team",
    image: "/tutorial/step-2.png",
    message: "Choose your favorite soccer league and select 5\n players who are scheduled to participate.",
  },
  {
    id: 3,
    title: "Get points for the real world",
    image: "/tutorial/step-3.png",
    message: "Points will be awarded according to the performance\n of the selected players in the actual match.",
  },
  {
    id: 4,
    title: "play tournaments",
    image: "/tutorial/step-4.png",
    message: "Numerous players are awaiting\ncompete with other players",
  },
  {
    id: 5,
    title: "Be a star",
    image: "/tutorial/step-5.png",
    message: "Pay minimum amount of money\nand be a billionaire",
  },
];

const TextComponent = ({ text }: { text: string }) => (
  <>
    {text.split("\n").map((line) => (
      <Fragment key={line}>
        {line}
        <br />
      </Fragment>
    ))}
  </>
);

const StepComponent = ({ step }: { step: Step }) => (
  <CarouselItem className={"text-center flex flex-col justify-between pl-0 w-full"}>
    <img src={step.image} alt={step.title} className="w-full" />

    <div className="flex-1 flex flex-col justify-start my-8 w-full">
      <p className="text-white text-[24px] leading-[24px]">
        <TextComponent text={step.title} />
      </p>
      <p className="text-[#B2B2B2] text-[14px] leading-[20px] mt-[10px]">
        <TextComponent text={step.message} />
      </p>
    </div>
  </CarouselItem>
);

type HowToPlayComponentProps = {
  api: CarouselApi | undefined;
  setApi: (api: CarouselApi) => void;
  currentStep: number;
  setCurrentStep: (step: number) => void;
  className?: string;
  carouselClassName?: string;
  autoPlay?: boolean;
};
export const HowToPlayComponent = ({
  api,
  setApi,
  currentStep,
  setCurrentStep,
  className,
  carouselClassName,
  autoPlay = false,
}: HowToPlayComponentProps) => {
  const plugins = useMemo(() => (autoPlay ? [Autoplay({ delay: 5000, stopOnInteraction: false })] : []), [autoPlay]);

  return (
    <div className={className}>
      <Carousel className={cn("w-screen", carouselClassName)} setApi={setApi} plugins={plugins}>
        <CarouselContent className="-ml-0">
          {steps.map((step) => (
            <StepComponent key={step.id} step={step} />
          ))}
        </CarouselContent>
      </Carousel>

      <div className="flex justify-center items-center gap-1">
        {steps.map((step, index) => (
          <div
            onClick={() => {
              setCurrentStep(index + 1);
              api?.scrollTo(index);
            }}
            key={step.id}
            className={cn(
              "cursor-pointer w-12 h-0.5 bg-[#4f4f4f]  rounded-none opacity-100",
              index === currentStep - 1 && " bg-gradient-to-r from-gradient-yellow to-gradient-green",
            )}
          />
        ))}
      </div>
    </div>
  );
};

export const TutorialPage = () => {
  const navigate = useNavigate();
  const [api, setApi] = useState<CarouselApi>();
  const [currentStep, setCurrentStep] = useState(1);

  const handleNext = () => {
    if (!api) {
      return;
    }

    if (api.canScrollNext()) {
      api.scrollNext();
      setCurrentStep(currentStep + 1);
    }

    if (currentStep === steps.length) {
      navigate("/");
    }
  };

  useEffect(() => {
    if (!api) {
      return;
    }

    api.on("select", () => {
      setCurrentStep(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  return (
    <div className="flex flex-col items-center font-koulen">
      <HowToPlayComponent api={api} setApi={setApi} currentStep={currentStep} setCurrentStep={setCurrentStep} />

      <PrimaryButton
        onClick={handleNext}
        text={currentStep === steps.length ? "Start tournament" : "Continue"}
        className="mt-[30px] rounded-full h-[55px] items-center flex justify-center w-5/6"
      />

      {currentStep !== steps.length && (
        <button onClick={() => navigate("/")} type="button" className="text-[#989898] mt-[30px] font-[14px]">
          Skip the tutorial
        </button>
      )}
    </div>
  );
};
