import { BackButton } from "@/components/Buttons/BackButton";
import { Center } from "@/components/Center";
import { Container } from "@/components/Container";
import { InfoIcon } from "@/components/Icons";
import { TonIcon } from "@/components/Icons";
import { Avatar } from "@/components/Icons/Avatar";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import Tabs from "@/components/Tabs";
import { TonUsdPill } from "@/components/TonUsdPill";
import { useErrorModalContext } from "@/contexts/ErrorModalContext";
import { useUserContext } from "@/contexts/UserContext";
import { useRoundEntryUnitResult } from "@/hooks/soccer/useRoundEntryUnitResult";
import { useSoccerRound } from "@/hooks/soccer/useSoccerRound";
import type { RomanApiError } from "@/lib/net/romanApi";
import type { EntryResult } from "@/types/Result";
import { getErrorMessage } from "@/utils/error";
import { fromNano } from "@ton/ton";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { RoundInfo } from "../Round/RoundInfo";
import { PlayerTable } from "./PlayerTable";
import { UserTable } from "./UserTable";

export const ResultPage = () => {
  const { roundEntryUnitId } = useParams();
  const location = useLocation();
  //TODO: https://app.asana.com/0/1207835129689105/1208583149556866/f
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get("matchName");
  const roundId = queryParams.get("roundId") as string;

  if (!roundEntryUnitId) {
    return <div>Error: Invalid roundEntryUnitId</div>;
  }

  const [isPlayerTab, setIsPlayerTab] = useState(true);
  const user = useUserContext();
  const { data: roundEntryUnitResultData, isLoading: isRoundEntryUnitResultLoading } = useRoundEntryUnitResult(
    user.id,
    roundEntryUnitId,
  );
  const [chosenEntry, setChosenEntry] = useState<EntryResult | null>(null);
  const [playerImageMap, setPlayerImageMap] = useState<Record<string, string | null>>({});

  const { openModal } = useErrorModalContext();
  const handleRoundError = useCallback(
    (error: RomanApiError) => {
      const message = getErrorMessage(error);
      openModal("Error getting round", message);
    },
    [openModal],
  );
  const { data: roundData, isLoading: isRoundDataLoading } = useSoccerRound(roundId, handleRoundError);
  const [isRoundInfoTabsOpen, setIsRoundInfoTabsOpen] = useState(false);

  useEffect(() => {
    const firstEntry = roundEntryUnitResultData?.result.entries.find((entry) => entry.userId === user.id);
    setChosenEntry(firstEntry ? firstEntry : null);
  }, [roundEntryUnitResultData?.result.entries, user.id]);

  useEffect(() => {
    if (!roundEntryUnitResultData) {
      return;
    }
    const newPlayerImageMap = roundEntryUnitResultData.result.players.reduce(
      (map, player) => {
        map[player.id] = player.imageUrl;
        return map;
      },
      {} as Record<string, string | null>,
    );
    setPlayerImageMap(newPlayerImageMap);
  }, [roundEntryUnitResultData]);

  if (isRoundEntryUnitResultLoading || roundEntryUnitResultData === undefined || !chosenEntry || isRoundDataLoading) {
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );
  }

  const { status, entries, players } = roundEntryUnitResultData.result;

  const userRank = chosenEntry.rank;
  const lowestRank = Math.max(...entries.map((entry) => entry.rank)) || 0;

  const onEntryClick = (entry: EntryResult | null) => {
    setChosenEntry(entry);
    setIsPlayerTab(true);
  };

  const togglePlayerTab = (isPlayerTab: boolean) => {
    const firstEntry = entries.find((entry) => entry.userId === user.id);
    if (!firstEntry) return;
    setChosenEntry(firstEntry);
    setIsPlayerTab(isPlayerTab);
  };

  return (
    <>
      <Container>
        <div className="flex mb-2.5">
          <BackButton className="self-start" />
          <div className="flex flex-col w-full justify-center items-center">
            <div className="flex justify-center items-center w-full">
              <p className="text-icon pr-1.5">{name}</p>
              <InfoIcon onClick={() => setIsRoundInfoTabsOpen(true)} />
            </div>
            <p className="text-icon text-xs">{status}</p>
          </div>
        </div>
        {isPlayerTab && (
          <>
            <div className="flex justify-end w-full">
              <TonUsdPill />
            </div>
            <div className="flex w-full justify-center items-center py-3 gap-2">
              <Avatar photoUrl={chosenEntry.userPhotoUrl} />
              <p className="text-sm text-icon">{chosenEntry.userName}</p>
            </div>
            <div className="flex w-full justify-center gap-10 text-icon">
              {userRank && lowestRank && (
                <div className="flex flex-col items-center">
                  <p className="text-xs">Rank</p>
                  <p className="text-xl">
                    {userRank} / {lowestRank}
                  </p>
                </div>
              )}
              <div className="flex flex-col items-center">
                <p className="text-xs">Prizes</p>
                <div className="flex justify-center items-center gap-1">
                  <TonIcon />
                  <p className="text-xl">{fromNano(chosenEntry.prize)}</p>
                </div>
              </div>
            </div>
          </>
        )}
      </Container>
      <Tabs defaultIndex={isPlayerTab ? 0 : 1}>
        <Tabs.Item title="Player" onClick={() => togglePlayerTab(true)}>
          <PlayerTable players={players} chosenEntry={chosenEntry} />
        </Tabs.Item>
        <Tabs.Item title="User" onClick={() => togglePlayerTab(false)}>
          <UserTable entries={entries} onEntryClick={onEntryClick} playerImageMap={playerImageMap} />
        </Tabs.Item>
      </Tabs>

      {isRoundInfoTabsOpen && !!roundData && (
        <RoundInfo
          sportName={roundData.round.abbreviatedName ? roundData.round.abbreviatedName : roundData.round.sportEventName}
          roundId={roundData.round.id}
          sportEventStartTime={roundData.round.sportEventStartTime}
          onClose={() => setIsRoundInfoTabsOpen(false)}
        />
      )}
    </>
  );
};
