import { ChevronRightIcon } from "@/components/Icons";
import { useNavigate } from "react-router-dom";
import { BottomNavigationBar } from "./Home/BottomNavigationBar";

export const HelpPage = () => {
  const navigate = useNavigate();

  return (
    <div className="pb-24 text-white">
      <p className="p-2.5 text-base text-center">Help</p>

      <div className="bg-button-background rounded-md mt-2.5">
        <div
          className="flex justify-between items-center text-sm py-3 px-[14px] cursor-pointer"
          onClick={() => navigate("/support")}
        >
          <span>Support</span>
          <ChevronRightIcon className="text-icon" strokeWidth={2.5} />
        </div>

        <hr style={{ borderColor: "rgba(255, 255, 255, 0.1)" }} />

        <div
          className="flex justify-between items-center text-sm py-3 px-[14px] cursor-pointer"
          onClick={() => navigate("/how-to-play")}
        >
          <span>How to Play</span>
          <ChevronRightIcon className="text-icon" strokeWidth={2.5} />
        </div>

        <hr style={{ borderColor: "rgba(255, 255, 255, 0.1)" }} />

        <div
          className="flex justify-between items-center text-sm py-3 px-[14px] cursor-pointer"
          onClick={() => navigate("/scoring-rule")}
        >
          <span>Scoring rule</span>
          <ChevronRightIcon className="text-icon" strokeWidth={2.5} />
        </div>
      </div>

      <BottomNavigationBar />
    </div>
  );
};
