import { cn } from "@/utils";
import type { GradientIconProps } from "./HelpIcon";

export const HistoryIcon = ({ gradientColors = ["#ADADAD", "#ADADAD"], className }: GradientIconProps) => {
  const id = `HistoryIconGradient-${gradientColors[0].substring(1)}-${gradientColors[1].substring(1)}`;

  return (
    <svg viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg" className={cn("w-5 h-5", className)}>
      <defs>
        <linearGradient id={id} x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor={gradientColors[0]} />
          <stop offset="100%" stopColor={gradientColors[1]} />
        </linearGradient>
      </defs>
      <path
        d="M9.5 18C7.2 18 5.19583 17.2375 3.4875 15.7125C1.77917 14.1875 0.8 12.2833 0.55 10H2.6C2.83333 11.7333 3.60417 13.1667 4.9125 14.3C6.22083 15.4333 7.75 16 9.5 16C11.45 16 13.1042 15.3208 14.4625 13.9625C15.8208 12.6042 16.5 10.95 16.5 9C16.5 7.05 15.8208 5.39583 14.4625 4.0375C13.1042 2.67917 11.45 2 9.5 2C8.35 2 7.275 2.26667 6.275 2.8C5.275 3.33333 4.43333 4.06667 3.75 5H6.5V7H0.5V1H2.5V3.35C3.35 2.28333 4.3875 1.45833 5.6125 0.875C6.8375 0.291667 8.13333 0 9.5 0C10.75 0 11.9208 0.2375 13.0125 0.7125C14.1042 1.1875 15.0542 1.82917 15.8625 2.6375C16.6708 3.44583 17.3125 4.39583 17.7875 5.4875C18.2625 6.57917 18.5 7.75 18.5 9C18.5 10.25 18.2625 11.4208 17.7875 12.5125C17.3125 13.6042 16.6708 14.5542 15.8625 15.3625C15.0542 16.1708 14.1042 16.8125 13.0125 17.2875C11.9208 17.7625 10.75 18 9.5 18ZM12.3 13.2L8.5 9.4V4H10.5V8.6L13.7 11.8L12.3 13.2Z"
        fill={`url(#${id})`}
      />
    </svg>
  );
};
