import { Center } from "@/components/Center";
import { CustomModal } from "@/components/CustomModal";
import { TonIcon } from "@/components/Icons";
import LoadingSpinner from "@/components/LoadingSpinner";
import { useErrorModalContext } from "@/contexts/ErrorModalContext";
import { useUserContext } from "@/contexts/UserContext";
import { useTransactions } from "@/hooks/payment/useTransactions";
import type { RomanApiError } from "@/lib/net/romanApi";
import type { Transaction } from "@/types/transaction";
import { getErrorMessage } from "@/utils/error";
import { fromNano } from "@ton/ton";
import { format } from "date-fns";
import { useCallback, useEffect, useState } from "react";

const displayTransactionStatus = (transaction: Transaction) => {
  return transaction.status === "completed"
    ? transaction.type === "deposit"
      ? "Received"
      : "Sent"
    : transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1);
};

export const TransactionHistory = () => {
  const user = useUserContext();

  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState<Transaction | null>(null);

  const { openModal } = useErrorModalContext();
  const handleUseTransactionError = useCallback(
    (error: RomanApiError) => {
      const message = getErrorMessage(error);
      openModal("Error getting transactions", message);
    },
    [openModal],
  );

  useEffect(() => {
    if (selectedTransaction) {
      setOpenModalDetail(true);
    } else {
      setOpenModalDetail(false);
    }
  }, [selectedTransaction]);

  const { data: transactions, isLoading } = useTransactions(user.id, handleUseTransactionError);

  if (isLoading || transactions === undefined) {
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );
  }

  const amount = selectedTransaction && Number(fromNano(selectedTransaction.nanoTon)).toFixed(2);
  const formattedAmount =
    selectedTransaction && selectedTransaction.type === "withdrawal" ? `-${amount}` : `+${amount}`;
  const formattedTime = selectedTransaction?.lastUpdateTime
    ? format(new Date(selectedTransaction.lastUpdateTime), "yyyy-MM-dd HH:mm")
    : null;

  const reportToSupport = (transaction: Transaction | null) => {
    if (!transaction) {
      return;
    }

    const encodedComment = encodeURIComponent(
      `Transaction: ${transaction.transactionComment}\nStatus: ${displayTransactionStatus(transaction)}\nAmount: ${formattedAmount}\nTime: ${formattedTime}`,
    );
    const supportUrl = `https://t.me/ThumbaSupportBot?text=${encodedComment}`;

    window.open(supportUrl, "_blank");
  };

  return (
    <div className="flex flex-col flex-grow overflow-y-auto h-full">
      {transactions.length > 0 && <p className="text-white text text-sm pb-2">Transaction History</p>}
      {transactions.map((transaction) => (
        <TransactionRow
          key={transaction.lastUpdateTime}
          transaction={transaction}
          setSelectedTransaction={setSelectedTransaction}
        />
      ))}

      <CustomModal
        className="bg-background"
        contentClassName="p-0 w-full h-screen"
        isOpen={openModalDetail}
        onClose={() => setOpenModalDetail(false)}
      >
        <div>
          <div className="w-full flex justify-center">
            <TonIcon className="w-20 h-20 text-white m-4" />
          </div>

          <div className="mt-3">
            <p className="text-2xl text-center font-bold text-white">
              <span>{formattedAmount}</span>
              <span className="ml-1">TON</span>
            </p>

            <p className="text-lg text-gray-400 mt-3 text-center">
              {selectedTransaction && displayTransactionStatus(selectedTransaction)}
            </p>

            {formattedTime && <p className="text-gray-400 text-lg mt-0.5 text-center">{formattedTime}</p>}
          </div>

          <div className="mt-10 text-white">
            <p>Need help with this transaction? Report it below:</p>
            <button
              type="button"
              onClick={() => reportToSupport(selectedTransaction)}
              className="w-full bg-button-background text-white p-2 rounded-md mt-2 hover:bg-button-hover"
            >
              Send Report
            </button>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

const TransactionRow = ({
  transaction,
  setSelectedTransaction,
}: { transaction: Transaction; setSelectedTransaction: (transaction: Transaction) => void }) => {
  const amount = Number(fromNano(transaction.nanoTon)).toFixed(2);
  const formattedAmount = transaction.type === "withdrawal" ? `-${amount}` : `+${amount}`;
  const truncatedAddress =
    transaction.type === "withdrawal" ? `${transaction.address.slice(0, 4)}..${transaction.address.slice(-4)}` : null;
  const formattedTime = transaction.lastUpdateTime
    ? format(new Date(transaction.lastUpdateTime), "yyyy-MM-dd HH:mm")
    : null;

  return (
    <div
      className="flex justify-between items-center border-t border-gray-700 py-2.5 cursor-pointer"
      onClick={() => setSelectedTransaction(transaction)}
    >
      <div className="flex flex-col justify-center">
        <div className="text-white text-sm">{displayTransactionStatus(transaction)}</div>
        {truncatedAddress && <div className="text-gray-400 text-xs mt-0.5">{truncatedAddress}</div>}
      </div>
      <div className="flex flex-col justify-center items-end">
        <div className="flex items-center">
          <TonIcon className="w-6 h-6" />
          <span className="text-white ml-1">{formattedAmount}</span>
        </div>
        {formattedTime && <div className="text-gray-400 text-xs mt-0.5">{formattedTime}</div>}
      </div>
    </div>
  );
};
