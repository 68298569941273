import { cn } from "@/utils";
import type { GradientIconProps } from "./HelpIcon";

export const HomeIcon = ({ gradientColors = ["#ADADAD", "#ADADAD"], className }: GradientIconProps) => {
  const id = `HomeIconGradient-${gradientColors[0].substring(1)}-${gradientColors[1].substring(1)}`;

  return (
    <svg viewBox="0 0 23 18" xmlns="http://www.w3.org/2000/svg" className={cn("w-5 h-5", className)}>
      <defs>
        <linearGradient id={id} x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor={gradientColors[0]} />
          <stop offset="100%" stopColor={gradientColors[1]} />
        </linearGradient>
      </defs>
      <path
        d="M3.5 18V8.625L1.7 10L0.5 8.4L3.5 6.1V3H5.5V4.575L11.5 0L22.5 8.4L21.3 9.975L19.5 8.625V18H3.5ZM5.5 16H10.5V12H12.5V16H17.5V7.1L11.5 2.525L5.5 7.1V16Z"
        fill={`url(#${id})`}
      />
    </svg>
  );
};
