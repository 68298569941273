import { cn } from "@/utils";

type IconProps = React.HTMLAttributes<SVGElement>;

export type GradientIconProps = IconProps & { gradientColors?: string[] };

export const HelpIcon = ({ gradientColors = ["#ADADAD", "#ADADAD"], className }: GradientIconProps) => {
  const id = `HelpIconGradient-${gradientColors[0].substring(1)}-${gradientColors[1].substring(1)}`;

  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={cn("w-5 h-5", className)}>
      <defs>
        <linearGradient id={id} x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor={gradientColors[0]} />
          <stop offset="100%" stopColor={gradientColors[1]} />
        </linearGradient>
      </defs>
      <path
        d="M12.45 18C12.8 18 13.0958 17.8792 13.3375 17.6375C13.5792 17.3958 13.7 17.1 13.7 16.75C13.7 16.4 13.5792 16.1042 13.3375 15.8625C13.0958 15.6208 12.8 15.5 12.45 15.5C12.1 15.5 11.8042 15.6208 11.5625 15.8625C11.3208 16.1042 11.2 16.4 11.2 16.75C11.2 17.1 11.3208 17.3958 11.5625 17.6375C11.8042 17.8792 12.1 18 12.45 18ZM11.55 14.15H13.4C13.4 13.6 13.4625 13.1667 13.5875 12.85C13.7125 12.5333 14.0667 12.1 14.65 11.55C15.0833 11.1167 15.425 10.7042 15.675 10.3125C15.925 9.92083 16.05 9.45 16.05 8.9C16.05 7.96667 15.7083 7.25 15.025 6.75C14.3417 6.25 13.5333 6 12.6 6C11.65 6 10.8792 6.25 10.2875 6.75C9.69583 7.25 9.28333 7.85 9.05 8.55L10.7 9.2C10.7833 8.9 10.9708 8.575 11.2625 8.225C11.5542 7.875 12 7.7 12.6 7.7C13.1333 7.7 13.5333 7.84583 13.8 8.1375C14.0667 8.42917 14.2 8.75 14.2 9.1C14.2 9.43333 14.1 9.74583 13.9 10.0375C13.7 10.3292 13.45 10.6 13.15 10.85C12.4167 11.5 11.9667 11.9917 11.8 12.325C11.6333 12.6583 11.55 13.2667 11.55 14.15ZM12.5 22C11.1167 22 9.81667 21.7375 8.6 21.2125C7.38333 20.6875 6.325 19.975 5.425 19.075C4.525 18.175 3.8125 17.1167 3.2875 15.9C2.7625 14.6833 2.5 13.3833 2.5 12C2.5 10.6167 2.7625 9.31667 3.2875 8.1C3.8125 6.88333 4.525 5.825 5.425 4.925C6.325 4.025 7.38333 3.3125 8.6 2.7875C9.81667 2.2625 11.1167 2 12.5 2C13.8833 2 15.1833 2.2625 16.4 2.7875C17.6167 3.3125 18.675 4.025 19.575 4.925C20.475 5.825 21.1875 6.88333 21.7125 8.1C22.2375 9.31667 22.5 10.6167 22.5 12C22.5 13.3833 22.2375 14.6833 21.7125 15.9C21.1875 17.1167 20.475 18.175 19.575 19.075C18.675 19.975 17.6167 20.6875 16.4 21.2125C15.1833 21.7375 13.8833 22 12.5 22ZM12.5 20C14.7333 20 16.625 19.225 18.175 17.675C19.725 16.125 20.5 14.2333 20.5 12C20.5 9.76667 19.725 7.875 18.175 6.325C16.625 4.775 14.7333 4 12.5 4C10.2667 4 8.375 4.775 6.825 6.325C5.275 7.875 4.5 9.76667 4.5 12C4.5 14.2333 5.275 16.125 6.825 17.675C8.375 19.225 10.2667 20 12.5 20Z"
        fill={`url(#${id})`}
      />
    </svg>
  );
};
