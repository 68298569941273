import { type User, initData, useSignal } from "@telegram-apps/sdk-react";

const formatUserName = (user: User) => {
  const { firstName, lastName } = user;
  if (!firstName) return lastName;
  if (!lastName) return firstName;
  return `${firstName} ${lastName}`;
};

type UseTelegram = {
  name: string | undefined;
  telegramUserId: number | undefined;
  telegramUserName: string | undefined;
  photoUrl: string | undefined;
  // Used to authenticate the user
  initDataRaw: string | undefined;
};
export const useTelegram = (): UseTelegram => {
  const initDataRaw = useSignal(initData.raw);
  const initDataState = useSignal(initData.state);

  const user = initDataState?.user;
  const telegramUserId = user?.id;

  return {
    name: user ? formatUserName(user) : undefined,
    photoUrl: user?.photoUrl,
    telegramUserName: user?.username,
    telegramUserId,
    initDataRaw,
  };
};
