export type PlayerScoring = {
  name: string;
  score: number;
};

export const ALL_PLAYER_SCORE: PlayerScoring[] = [
  {
    name: "Goal",
    score: 10,
  },
  {
    name: "Assist",
    score: 6,
  },
  {
    name: "Shot",
    score: 1,
  },
  {
    name: "Shot on Goal",
    score: 1,
  },
  {
    name: "Crosses",
    score: 0.7,
  },
  {
    name: "Assisted Shot",
    score: 1,
  },
  {
    name: "Accurate Pass",
    score: 0.02,
  },
  {
    name: "Foul Drawn",
    score: 1,
  },
  {
    name: "Foul Conceded",
    score: -0.5,
  },
  {
    name: "Tackle Won",
    score: 1,
  },
  {
    name: "Passes Intercepted (D, M, F)",
    score: 0.5,
  },
  {
    name: "Yellow Card",
    score: -1.5,
  },
  {
    name: "Red Card",
    score: -3,
  },
  {
    name: "Clean Sheet (D)",
    score: 3,
  },
];

export const GOAL_KEEPER_PLAYER_SCORE = [
  {
    name: "Saves",
    score: 2,
  },
  {
    name: "Goal Conceded",
    score: -2,
  },
  {
    name: "Clean Sheet",
    score: 5,
  },
  {
    name: "Win",
    score: 5,
  },
  {
    name: "Penalty Kick Save",
    score: 3,
  },
];

export const PLAYER_STATS_DISPLAY_MAP: Record<string, string> = {
  goalsScored: "Goal",
  assists: "Assist",
  shot: "Shot",
  shotOnGoal: "Shot on Goal",
  crosses: "Crosses",
  assistedShot: "Assisted Shot",
  accuratePass: "Accurate Pass",
  foulDrawn: "Foul Drawn",
  foulConceded: "Foul Conceded",
  tackleWon: "Tackle Won",
  passesIntercepted: "Passes Intercepted",
  yellowCard: "Yellow Card",
  redCard: "Red Card",
  cleanSheet: "Clean Sheet",

  // These stats are only for goal keepers
  saves: "Saves",
  goalConceded: "Goal Conceded",
  goalkeeperCleanSheet: "Clean Sheet",
  win: "Win",
  penaltyKickSave: "Penalty Kick Save",
};
