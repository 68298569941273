type IconProps = React.HTMLAttributes<SVGElement>;

export const ChevronDownIcon = (props: IconProps) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.29289 9.79289C5.93241 10.1534 5.90468 10.7206 6.2097 11.1129L6.29289 11.2071L11.2929 16.2071C11.6534 16.5676 12.2206 16.5953 12.6129 16.2903L12.7071 16.2071L17.7071 11.2071C18.0976 10.8166 18.0976 10.1834 17.7071 9.79289C17.3466 9.43241 16.7794 9.40468 16.3871 9.7097L16.2929 9.79289L12 14.085L7.70711 9.79289C7.34662 9.43241 6.77939 9.40468 6.3871 9.7097L6.29289 9.79289Z"
        fill="currentColor"
      />
    </svg>
  );
};
