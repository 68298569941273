import RomanApi, { type RomanApiError } from "@/lib/net/romanApi";
import type { EntryDetails } from "@/types/EntryDetails";
import { useEffect } from "react";
import type { Key } from "swr";
import useSWRMutation from "swr/mutation";

type createEntryArg = {
  entryTx: EntryDetails;
  initDataRaw: string;
};

const postCreateEntryRequest = async (url: string, { arg }: { arg: createEntryArg }) => {
  return await RomanApi.post(url, arg);
};

export const useCreateEntry = (handleError: (err: RomanApiError) => void) => {
  const { data, isMutating, trigger, error } = useSWRMutation<any, RomanApiError, Key, createEntryArg>(
    "/soccer/entry",
    postCreateEntryRequest,
  );

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);

  return {
    data,
    trigger,
    isMutating,
  };
};
