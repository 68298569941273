import { BackButton } from "@/components/Buttons/BackButton";
import { PrimaryButton } from "@/components/Buttons/PrimaryButton";
import { BottomNavigationBar, NavigationItemId } from "./Home/BottomNavigationBar";

export default function SupportPage() {
  return (
    <div className="p-2.5 pb-24 text-white h-screen flex flex-col">
      <div className="flex justify-center relative">
        <BackButton className="absolute left-0 cursor-pointer" />

        <p className="text-white">Support</p>
      </div>

      <div className="flex flex-col items-center justify-center mt-32">
        <img className="mb-4" src="/assets/thumba-coming-soon.svg" alt="thumba img" />

        <div className="mt-4 flex flex-col items-center justify-center">
          <p className="text-base text-center leading-[20px]">Contact support</p>
          <p className="text-xs text-icon text-center leading-[15px] mt-1.5">
            To contact the support service,
            <br />
            you will leave the game
          </p>
        </div>

        <PrimaryButton
          className="mt-6 cursor-pointer"
          onClick={() => window.open("https://t.me/ThumbaSupportBot")}
          text="Contact support"
        />
      </div>

      <BottomNavigationBar active={NavigationItemId.Help} />
    </div>
  );
}
