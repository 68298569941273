import { useErrorModalContext } from "@/contexts/ErrorModalContext";
import romanApi, { type RomanApiError } from "@/lib/net/romanApi";
import { getErrorMessage } from "@/utils/error";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { useTelegram } from "./useTelegram";
import { useUpdateUser } from "./useUpdateUser";

const POLLING_INTERVAL = 10000;
interface ResponseUser {
  user: User;
}

interface GetUserRequest {
  telegramUserId: number;
}

const fetchUser = (url: string, telegramUserId: number) => {
  return romanApi.get<ResponseUser, GetUserRequest>(url, { telegramUserId });
};

interface CreateUserRequest {
  telegramUserId: number;
  name: string;
  telegramUserName: string | undefined;
}

export const useUser = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const { telegramUserId, name, photoUrl, telegramUserName } = useTelegram();

  const { data, mutate } = useSWR<ResponseUser>(
    ["/user", telegramUserId],
    ([url, telegramUserId]) => fetchUser(url, Number(telegramUserId)),
    { revalidateOnFocus: false }, // Disable auto-revalidation on window focus
  );

  const { openModal } = useErrorModalContext();
  const handleError = useCallback(
    (error: RomanApiError) => {
      const message = getErrorMessage(error);
      openModal("Error when updating user", message);
    },
    [openModal],
  );

  const { trigger: triggerUpdateUser } = useUpdateUser(handleError);

  useEffect(() => {
    if (!data) {
      return;
    }

    const { user } = data;

    if (user) {
      updateUser(user);
    } else {
      createUser();
    }
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (telegramUserId) {
        mutate();
      }
    }, POLLING_INTERVAL); // 5 seconds interval

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [telegramUserId, mutate]);

  const updateUser = async (user: User) => {
    setUser(user);

    if (user.photoUrl !== photoUrl) {
      await triggerUpdateUser({ userId: user.id, photoUrl: photoUrl || "" });

      mutate();
    }
  };

  const createUser = async () => {
    if (!telegramUserId || !name) return;

    const data = await romanApi.post<ResponseUser, CreateUserRequest>("/user", {
      telegramUserId,
      name,
      telegramUserName,
    });

    setUser(data.user);

    navigate("/tutorial");
  };

  return {
    user,
    setUser,
  };
};
