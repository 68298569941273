import { BackButton } from "@/components/Buttons/BackButton";
import { PrimaryButton } from "@/components/Buttons/PrimaryButton";
import { Center } from "@/components/Center";
import { Container } from "@/components/Container";
import { Deadline } from "@/components/Deadline";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { useErrorModalContext } from "@/contexts/ErrorModalContext";
import { useUserContext } from "@/contexts/UserContext";
import { useSchedule } from "@/hooks/soccer/useSchedule";
import { useCountDown } from "@/hooks/useCountDown";
import type { RomanApiError } from "@/lib/net/romanApi";
import { cn } from "@/utils";
import { getErrorMessage } from "@/utils/error";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { PlayerCard } from "../Round/PlayerCard";
import { RoundInfoBanner } from "../Round/RoundInfo/RoundInfoBanner";

export const SelectPlayerPage: React.FC = () => {
  const [isRoundInfoTabsOpen, setIsRoundInfoTabsOpen] = useState(false);
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState(0);

  const { roundEntryUnitId, entryId } = useParams<{ roundEntryUnitId: string; entryId: string }>();

  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location]);
  const name = queryParams.get("matchName");
  const playerId = queryParams.get("playerId") ?? "";

  const { openModal } = useErrorModalContext();
  const handleError = useCallback(
    (error: RomanApiError) => {
      const message = getErrorMessage(error);
      openModal("Error: ", message);
    },
    [openModal],
  );

  const user = useUserContext();

  if (!roundEntryUnitId) {
    throw new Error("Invalid roundEntryUnitId in SelectPlayerPage");
  }
  const { roundEntryUnit, round, entries, isLoading } = useSchedule(roundEntryUnitId, user.id, handleError);

  const maxPlayerSelections = round?.type === "Round0001" ? 11 : 5;

  const { homePlayers, awayPlayers } = useMemo(() => {
    return {
      homePlayers: round?.players.homePlayers || [],
      awayPlayers: round?.players.awayPlayers || [],
    };
  }, [round?.players]);

  const { selectedPlayers, selectedPlayersMap } = useMemo(() => {
    if (entryId) {
      const selectedEntry = entries.find((entry) => entry.id === entryId);

      const selectedPlayersMap: Map<string, boolean> =
        selectedEntry?.selectedPlayers.reduce((map, playerId) => {
          map.set(playerId, true);
          return map;
        }, new Map<string, boolean>()) ?? new Map();

      const selectedPlayers = [];
      for (let i = 0; i < maxPlayerSelections; i++) {
        const homePlayer = homePlayers[i];
        const awayPlayer = awayPlayers[i];

        const selectPlayer = selectedPlayersMap.get(homePlayer?.id) ? homePlayer : awayPlayer;

        if (selectPlayer) {
          selectedPlayers.push(selectPlayer);
        }
      }

      return {
        selectedPlayers,
        selectedPlayersMap,
      };
    }

    return {
      selectedPlayers: [],
      selectedPlayersMap: new Map(),
    };
  }, [entryId, entries, homePlayers, awayPlayers, maxPlayerSelections]);

  useEffect(() => {
    if (playerId) {
      const playerIndex = selectedPlayers.findIndex((x) => x.id === playerId);

      setCurrentSelectedIndex(playerIndex !== -1 ? playerIndex : 0);
    }
  }, [playerId, selectedPlayers]);

  if (isLoading || !roundEntryUnit || !round || !name) {
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );
  }

  const handlePlayerSlotClick = (index: number) => {
    if (index >= 0 && index < selectedPlayers.length) {
      setCurrentSelectedIndex(index);
    }
  };

  return (
    <Container className="flex flex-col h-screen">
      <div className="flex mb-10">
        <BackButton />
        <RoundInfoBanner
          round={round}
          isRoundInfoTabsOpen={isRoundInfoTabsOpen}
          onCloseRoundInfoTabs={() => setIsRoundInfoTabsOpen(false)}
          onOpenRoundInfoTabs={() => setIsRoundInfoTabsOpen(true)}
        />
      </div>
      <DeadlineTimeComponent entryCloseTime={round.entryCloseTime ?? 0} />
      <div className="fle flex-col px-10 h-full">
        <p className="text-center text-white mb-4 text-sm">Select Your Team</p>
        <div className="flex gap-[20px] justify-center">
          <PlayerCard
            player={homePlayers[currentSelectedIndex]}
            roundId={round.id}
            onClick={() => {}}
            isSelected={selectedPlayersMap.get(homePlayers[currentSelectedIndex].id) ?? false}
            hasAnySelection
          />
          <PlayerCard
            player={awayPlayers[currentSelectedIndex]}
            roundId={round.id}
            onClick={() => {}}
            isSelected={selectedPlayersMap.get(awayPlayers[currentSelectedIndex].id) ?? false}
            hasAnySelection
          />
        </div>
      </div>

      <div className="flex flex-wrap gap-1 justify-center items-center -mx-3 h-full">
        {Array.from({ length: maxPlayerSelections }, (_, index) => {
          const player = selectedPlayers[index];
          if (!player) return <div className="rounded-md w-7 h-10 bg-button-background" key={uuidv4()} />;

          return (
            <div
              onClick={() => handlePlayerSlotClick(index)}
              className={cn(
                "rounded-md w-7 h-10 bg-button-background hover:cursor-pointer",
                currentSelectedIndex === index && "border border-gradient-yellow",
              )}
              key={player.id}
            >
              <img src={player.imageUrl || undefined} alt={player.name} />
            </div>
          );
        })}
      </div>

      <div className="flex justify-end items-end">
        <PrimaryButton text="Enter" onClick={() => {}} disabled={true} />
      </div>
    </Container>
  );
};

const DeadlineTimeComponent: React.FC<{ entryCloseTime: number }> = ({ entryCloseTime }) => {
  const { deadlineTime } = useCountDown(new Date(entryCloseTime ?? 0));

  return <Deadline deadlineTime={deadlineTime} />;
};
