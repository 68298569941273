import { BackButton } from "@/components/Buttons/BackButton";
import { BottomNavigationBar, NavigationItemId } from "./Home/BottomNavigationBar";
import { FantasyPointChart } from "./Round/RoundInfo/FantasyPointChart";

export const ScoringRulePage = () => {
  return (
    <div className="p-2.5 pb-24 text-white flex flex-col">
      <div className="flex justify-center relative -mx-2.5">
        <BackButton className="absolute left-0 cursor-pointer" />

        <p className="text-white">Scoring rule</p>
      </div>

      <div className="mt-2.5">
        <FantasyPointChart />
      </div>

      <BottomNavigationBar active={NavigationItemId.Help} />
    </div>
  );
};
