type IconProps = React.HTMLAttributes<SVGElement>;

export const ChevronRightIcon = ({ strokeWidth = 2, ...props }: IconProps & { strokeWidth?: number }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      {...props}
    >
      <path d="m9 18 6-6-6-6" />
    </svg>
  );
};
