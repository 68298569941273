import { CheckIcon, CheckSuccessfulIcon, TonIcon } from "@/components/Icons";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { TonUsdPill } from "@/components/TonUsdPill";
import { useTonUsdPriceContext } from "@/contexts/TonUsdContext";
import type { RoundEntryUnit } from "@/types/RoundEntryUnit";
import { fromNano } from "@ton/ton";
import { useState } from "react";
import type { EntryProps } from "./index";

export const Entry = ({
  round,
  entryDetails,
  setEntryDetails,
  totalFee,
  setTotalFee,
  setCanEnter,
  enterButton,
  roundEntryNumEntriesMap,
}: EntryProps & {
  totalFee: number;
  setTotalFee: React.Dispatch<React.SetStateAction<number>>;
  setCanEnter: (canEnter: boolean) => void;
  enterButton: React.ReactNode;
  roundEntryNumEntriesMap: Map<string, number>;
}) => {
  const roundEntries: RoundEntryUnit[] = round.roundEntryUnits;

  const updateEntryDetails = (roundEntryUnitId: string, numOfEntry: number) => {
    let newEntries = [...entryDetails.entries];
    let newRoundEntryUnit = newEntries.find((entry) => entry.roundEntryUnitId === roundEntryUnitId);

    if (numOfEntry === 0) {
      // Remove the entry if numOfEntry is 0
      newEntries = newEntries.filter((entry) => entry.roundEntryUnitId !== roundEntryUnitId);
    } else if (newRoundEntryUnit) {
      // Update existing entry
      newEntries = newEntries.map((entry) => {
        if (entry.roundEntryUnitId === roundEntryUnitId) {
          return {
            ...entry,
            numEntries: numOfEntry,
          };
        }
        return entry;
      });
    } else {
      // Add new entry
      newRoundEntryUnit = { roundEntryUnitId, numEntries: numOfEntry };
      newEntries.push(newRoundEntryUnit);
    }

    if (newEntries.length > 0) setCanEnter(true);
    else setCanEnter(false);

    setEntryDetails({
      ...entryDetails,
      entries: newEntries,
    });
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-end my-4">
        <TonUsdPill />
      </div>
      <div className="flex flex-col gap-2.5 mb-6">
        {roundEntries.map((entry) => (
          <EntryRow
            {...entry}
            updateEntryDetails={updateEntryDetails}
            updateTotalFee={setTotalFee}
            userNumEntries={roundEntryNumEntriesMap.get(entry.id) ?? 0}
            key={entry.id}
          />
        ))}
      </div>
      <div className="flex flex-col justify-end h-full gap-6">
        <div className="flex flex-col justify-center items-center gap-1">
          <p className="text-white text-xs text-center">Total Entry fee</p>
          <div className="flex gap-1">
            <TonIcon className="w-6 h-6" />
            <p className="text-white text-xl">{fromNano(totalFee)}</p>
          </div>
        </div>
        {enterButton}
      </div>
    </div>
  );
};

const EntryRow = ({
  id,
  entryFee,
  numEntries,
  userNumEntries,
  maxNumEntries,
  maxNumEntriesPerUser,
  prizePool,
  updateEntryDetails,
  updateTotalFee,
}: RoundEntryUnit & {
  userNumEntries: number;
  updateEntryDetails: (entryId: string, numOfEntry: number) => void;
  updateTotalFee: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [numOfEntrySelected, setNumOfEntrySelected] = useState(0);

  const maxEntryCanSelect = maxNumEntriesPerUser ? maxNumEntriesPerUser - userNumEntries : maxNumEntries - numEntries;

  const { tonUsdPrice } = useTonUsdPriceContext();

  const totalUsdPrize =
    tonUsdPrice !== undefined ? `$${(Number(fromNano(prizePool)) * tonUsdPrice).toFixed(2)}` : undefined;

  const handleAddAnEntry = () => {
    if (numOfEntrySelected < maxEntryCanSelect) {
      setNumOfEntrySelected((prevNum) => {
        const newNum = prevNum + 1;
        updateEntryDetails(id, newNum);
        updateTotalFee((prevFee) => prevFee + Number.parseFloat(entryFee));
        return newNum;
      });
    }
  };
  const handleRemoveAnEntry = () => {
    setNumOfEntrySelected((prevNum) => {
      const newNum = prevNum - 1;
      updateEntryDetails(id, newNum);
      updateTotalFee((prevFee) => prevFee - Number.parseFloat(entryFee));

      return newNum;
    });
  };

  return (
    <div
      className={`flex gap-2 rounded-md p-4 z-0
        ${
          numOfEntrySelected > 0
            ? "border border-[00E218] bg-gradient-to-br from-[#003105] to-[#2D2800]"
            : "border border-solid hover:cursor-pointer"
        }`}
      onClick={() => {
        if (!numOfEntrySelected && maxEntryCanSelect) {
          setNumOfEntrySelected(() => {
            const newNum = 1;
            updateEntryDetails(id, newNum);
            updateTotalFee((prevFee) => prevFee + Number.parseFloat(entryFee));
            return newNum;
          });
        }
      }}
    >
      <div className="flex items-center">
        {numOfEntrySelected > 0 ? (
          <CheckSuccessfulIcon
            className="w-7 h-7"
            onClick={() => {
              if (numOfEntrySelected > 0) {
                setNumOfEntrySelected(() => {
                  const prevNum = numOfEntrySelected;
                  updateEntryDetails(id, 0);
                  updateTotalFee((prevFee) => prevFee - prevNum * Number.parseFloat(entryFee));
                  return 0;
                });
              }
            }}
          />
        ) : (
          <CheckIcon className="w-7 h-7" />
        )}
      </div>

      <div className="flex flex-col gap-2 w-full">
        <div className="flex justify-between w-full items-center">
          <div className="flex flex-col gap-1">
            <p className="text-white text-xs">Entry fee</p>
            <div className="flex items-center gap-1">
              {entryFee === "0" ? (
                <p className="text-white text-base text-xl">Free</p>
              ) : (
                <>
                  <TonIcon className="w-6 h-6" />
                  <p className="text-white text-xl text-base">{fromNano(entryFee)}</p>
                </>
              )}
            </div>
          </div>

          <div className="flex flex-col gap-0.5">
            <div className="flex gap-4">
              <div className="flex flex-col gap-1">
                <p className="text-icon text-xs">Total prizes</p>
                <div className="flex gap-1">
                  <TonIcon className="w-5 h-5" />
                  <p className="text-icon">
                    {fromNano(prizePool)}
                    {totalUsdPrize ? (
                      <span className="text-xs">({totalUsdPrize})</span>
                    ) : (
                      <LoadingSpinner size="sm" isSpan />
                    )}
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-icon text-xs">Entries</p>
                <p className="text-icon text-s">
                  {numEntries}/{maxNumEntries}
                </p>
              </div>
            </div>
            {!numOfEntrySelected && (
              <p className="text-[#5C5C5C] text-xs">{`Entry Limits = Multi-Entry(${maxNumEntriesPerUser})`}</p>
            )}
          </div>
        </div>
        {numOfEntrySelected > 0 && (
          <>
            <div className="flex justify-between py-3 px-4 rounded-[50px] bg-white/10 z-10">
              <img
                className="hover:cursor-pointer"
                onClick={handleRemoveAnEntry}
                src="/assets/minus.svg"
                alt="minus icon"
              />
              <p className="text-white font-bold">{numOfEntrySelected}</p>
              <img className="hover:cursor-pointer" onClick={handleAddAnEntry} src="/assets/plus.svg" alt="plus icon" />
            </div>
            {maxNumEntriesPerUser && (
              <p className="text-icon text-xs text-center">{`Entry Limits = ${userNumEntries}/${maxNumEntriesPerUser}`}</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};
