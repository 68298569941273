import App from "@/app";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { type FC, useEffect, useState } from "react";
import { Center } from "./components/Center";
import { LoadingSpinner } from "./components/LoadingSpinner";

const ErrorBoundaryError: FC<{ error: unknown }> = ({ error }) => (
  <div className="text-white w-[90%] h-screen flex flex-col items-center justify-center max-w-[90%] mx-auto">
    <img src="/assets/logo.svg" alt="logo" className="h-10" />
    <h2 className="text-xl font-bold mt-4 mb-2">An unhandled error occurred:</h2>
    <code className="bg-gray-800 p-2 rounded-md mt-2">
      {error instanceof Error ? error.message : typeof error === "string" ? error : JSON.stringify(error)}
    </code>
  </div>
);

const Inner: FC = () => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://telegram.org/js/telegram-web-app.js";
    script.async = true;
    script.onload = () => {
      if (window.Telegram?.WebApp) {
        window.Telegram.WebApp.disableVerticalSwipes();
        window.Telegram.WebApp.expand();
      }
    };

    document.body.appendChild(script);

    setInitialized(true);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (!initialized) {
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );
  }

  return <App />;
};

export const Root: FC = () => (
  <ErrorBoundary fallback={ErrorBoundaryError}>
    <Inner />
  </ErrorBoundary>
);
