import { BackButton } from "@/components/Buttons/BackButton";
import { PrimaryButton } from "@/components/Buttons/PrimaryButton";
import { Center } from "@/components/Center";
import { Container } from "@/components/Container";
import { CustomModal } from "@/components/CustomModal";
import { Deadline } from "@/components/Deadline";
import LoadingSpinner from "@/components/LoadingSpinner";
import Tabs from "@/components/Tabs";
import { useErrorModalContext } from "@/contexts/ErrorModalContext";
import { useUserContext } from "@/contexts/UserContext";
import { useUserSoccerRoundDetails } from "@/hooks/soccer/useSoccerUserRoundDetails";
import { useCountDown } from "@/hooks/useCountDown";
import type { RomanApiError } from "@/lib/net/romanApi";
import type { EntryDetails } from "@/types/EntryDetails";
import type { RoundDetails } from "@/types/RoundDetails";
import { getErrorMessage } from "@/utils/error";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import type { RoundPageType } from "..";
import { RoundInfoBanner } from "../RoundInfo/RoundInfoBanner";
import { SuccessModal, SufficientModal } from "../modals";
import { Entry } from "./Entry";
import { Prize } from "./Prize";
export interface EntryProps {
  round: RoundDetails;
  setEntryDetails: (body: EntryDetails) => void;
  entryDetails: EntryDetails;
  setCurrentPage: (page: RoundPageType) => void;
}

export const SelectEntry: React.FC<EntryProps> = (props) => {
  const { round, setCurrentPage } = props;
  const user = useUserContext();
  const { openModal } = useErrorModalContext();
  const handleError = useCallback(
    (error: RomanApiError) => {
      const message = getErrorMessage(error);
      openModal("Error getting round", message);
    },
    [openModal],
  );
  const { data, isLoading } = useUserSoccerRoundDetails(round.id, user.id, handleError);

  const [isRoundInfoTabsOpen, setIsRoundInfoTabsOpen] = useState(false);
  const [totalFee, setTotalFee] = useState(0);
  const [canEnter, setCanEnter] = useState(false);
  const [isSufficientModalOpen, setIsSufficientModalOpen] = useState(false);
  const [isInsufficientModalOpen, setIsInsufficientModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const navigate = useNavigate();

  if (isLoading || !data)
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );

  if (!round) return null;

  const roundEntryNumEntriesMap = data.userRound.roundEntryUnits.reduce((map, unit) => {
    map.set(unit.id, unit.userNumEntries);
    return map;
  }, new Map<string, number>());

  const balance = Number.parseInt(user.nanoTon);

  const enterButton = (
    <PrimaryButton
      className="w-full align-end"
      text="ENTER"
      onClick={async () => {
        if (balance >= totalFee) {
          // go to buy modal
          setIsSufficientModalOpen(true);
        } else {
          // no funds
          setIsInsufficientModalOpen(true);
        }
      }}
      disabled={!canEnter}
    />
  );

  const onClickSuccessModal = () => {
    props.setEntryDetails({
      ...props.entryDetails,
      roundType: null,
      playerIds: [],
      entries: [],
      detail: {
        type: null,
        captainPlayerId: null,
        viceCaptainPlayerId: null,
      },
    });
    setCurrentPage("select-player");
  };

  return (
    <Container className="flex flex-col">
      <div className="flex mb-2.5">
        <BackButton onClick={() => setCurrentPage("select-captain")} />
        <RoundInfoBanner
          round={round}
          isRoundInfoTabsOpen={isRoundInfoTabsOpen}
          onCloseRoundInfoTabs={() => setIsRoundInfoTabsOpen(false)}
          onOpenRoundInfoTabs={() => setIsRoundInfoTabsOpen(true)}
        />
      </div>

      <DeadlineTimeComponent entryCloseTime={round.entryCloseTime ?? 0} />

      <Tabs>
        <Tabs.Item title="Entry">
          <Entry
            {...props}
            totalFee={totalFee}
            setTotalFee={setTotalFee}
            setCanEnter={setCanEnter}
            enterButton={enterButton}
            roundEntryNumEntriesMap={roundEntryNumEntriesMap}
          />
        </Tabs.Item>
        <Tabs.Item title="Prizes">
          <Prize totalFee={totalFee} enterButton={enterButton} {...props} />
        </Tabs.Item>
      </Tabs>

      <SufficientModal
        isOpen={isSufficientModalOpen}
        balance={balance}
        totalFee={totalFee}
        onClose={() => setIsSufficientModalOpen(false)}
        entryDetails={props.entryDetails}
        onSuccess={() => setIsSuccessModalOpen(true)}
      />

      <CustomModal isOpen={isInsufficientModalOpen} onClose={() => setIsInsufficientModalOpen(false)}>
        <div className="flex flex-col text-white text-center">
          <div className="flex flex-col text-white text-center">
            <p className="text-base">Insufficient funds, please deposit Ton.</p>
          </div>
          <PrimaryButton className="w-full mt-6" text="ADD FUNDS" onClick={() => navigate("/balance")} />
        </div>
      </CustomModal>

      <SuccessModal isOpen={isSuccessModalOpen} onClose={() => navigate("/")} onClick={onClickSuccessModal} />
    </Container>
  );
};

// TODO: Make this component reusable. We need to separate it to avoid re-rendering on every countdown.
// https://app.asana.com/0/1207835129689105/1209046207853675
const DeadlineTimeComponent: React.FC<{ entryCloseTime: number }> = ({ entryCloseTime }) => {
  const { deadlineTime } = useCountDown(new Date(entryCloseTime ?? 0));

  return <Deadline deadlineTime={deadlineTime} />;
};
