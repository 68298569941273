import { PrimaryButton } from "@/components/Buttons/PrimaryButton";
import { Avatar } from "@/components/Icons/Avatar";
import { useUserContext } from "@/contexts/UserContext";
import { useCountDown } from "@/hooks/useCountDown";
import type { RoundDetails } from "@/types/RoundDetails";
import type { ScheduledEntry } from "@/types/Schedule";
import { cn } from "@/utils";
import _ from "lodash";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

interface UserTabProps {
  round: RoundDetails;
  entries: ScheduledEntry[];
  onClick: (entry: ScheduledEntry) => void;
}

export const UserEntries = ({ round, entries, onClick }: UserTabProps) => {
  const isEndedRound = useMemo(() => round.entryCloseTime < new Date().getTime(), [round.entryCloseTime]);

  return (
    <div>
      <div className="flex flex-col gap-[2px] overflow-y-auto flex-grow">
        {entries.map((entry, index) => {
          return (
            <UserEntry
              key={entry.id}
              index={index}
              entry={entry}
              onClick={onClick}
              round={round}
              isEndedRound={isEndedRound}
            />
          );
        })}
      </div>

      <EntryButton roundId={round.id} entryCloseTime={round.entryCloseTime} />
    </div>
  );
};

const EntryButton = ({ roundId, entryCloseTime }: { roundId: string; entryCloseTime: number }) => {
  const navigate = useNavigate();
  const { isOver: isEndedRound } = useCountDown(new Date(entryCloseTime));

  return (
    <div className="mt-3">
      <PrimaryButton
        className="w-full mt-6"
        text="ADD ENTRY"
        disabled={isEndedRound}
        onClick={() => {
          navigate(`/rounds/${roundId}`);
        }}
      />
    </div>
  );
};

type UserEntryProps = {
  entry: ScheduledEntry;
  round: RoundDetails;
  index: number;
  isEndedRound: boolean;
  onClick: (entry: ScheduledEntry) => void;
};
const UserEntry = ({ entry, round, index, isEndedRound, onClick }: UserEntryProps) => {
  const { selectedCaptains } = entry;
  const user = useUserContext();
  const isOwnEntry = entry.userId === user.id;

  const isCaptain = (playerId: string): boolean => {
    return selectedCaptains.captainPlayerId === playerId;
  };

  const isViceCaptain = (playerId: string): boolean => {
    if (selectedCaptains.type === "0002") {
      return selectedCaptains.viceCaptainPlayerId === playerId;
    }
    return false;
  };

  const selectedPlayers = useMemo(() => {
    if (!isOwnEntry && !isEndedRound) {
      return [];
    }

    const selectedPlayersMap = entry.selectedPlayers.reduce(
      (map, playerId) => {
        map[playerId] = true;
        return map;
      },
      {} as Record<string, boolean>,
    );

    const result = [];
    for (const i in round.players.homePlayers) {
      const homePlayer = round.players.homePlayers[i];
      const awayPlayer = round.players.awayPlayers[i];
      const selectPlayer = selectedPlayersMap[homePlayer.id] ? homePlayer : awayPlayer;

      if (selectPlayer) {
        result.push(selectPlayer);
      }
    }

    return result;
  }, [entry, round.players, isOwnEntry, isEndedRound]);

  const handleClickUserEntry = () => {
    if (entry.userId === user.id || (entry.userId !== user.id && isEndedRound)) {
      onClick(entry);
    }
  };

  return (
    <div
      className={cn(
        "flex gap-1 items-center w-full text-icon py-2 cursor-pointer",
        isOwnEntry && "bg-gradient-dark-to-transparent",
      )}
      onClick={handleClickUserEntry}
    >
      <p className="px-2 pl-5">{index + 1}</p>

      <div className="px-2">
        <Avatar photoUrl={entry.userPhotoUrl} />
      </div>

      <div className="w-5/6 flex-col">
        <p>{entry.userName}</p>
        <div className="relative w-full">
          <div className="flex pl-1.5 pt-1.5 justify-start gap-2 overflow-x-scroll w-full hide-scrollbar">
            {selectedPlayers.map((player) => (
              <div
                className="p-0.5 rounded-md"
                style={{
                  padding: "1px",
                  backgroundImage:
                    isCaptain(player.id) || isViceCaptain(player.id)
                      ? "linear-gradient(to bottom right, #FFE500, #00E218)"
                      : "none",
                  WebkitBackgroundClip: "padding-box",
                  backgroundClip: "padding-box",
                  overflow: "visible",
                }}
                key={player.id}
              >
                <div className="relative bg-chicago-700 rounded-md w-8 h-12">
                  {(isCaptain(player.id) || isViceCaptain(player.id)) && (
                    <div
                      className="absolute top-0 left-0 text-xs font-bold text-black rounded-full z-10 flex items-center justify-center"
                      style={{
                        width: "1.75rem",
                        backgroundImage: "linear-gradient(to bottom right, #FFE500, #00E218)",
                        transform: "translate(-25%, -35%)",
                      }}
                    >
                      {isCaptain(player.id) ? "C" : "VC"}
                    </div>
                  )}
                  <img
                    key={player.id}
                    src={player.imageUrl || "#"}
                    alt="player"
                    className="w-8 h-12 rounded-md select-none cursor-pointer"
                    draggable={false}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
