import romanApi, { type RomanApiError } from "@/lib/net/romanApi";
import type { Transaction } from "@/types/transaction";
import { useEffect } from "react";
import useSWR from "swr";

interface useTransactionsResponse {
  transactions: Transaction[];
}

const fetcher = (url: string): Promise<useTransactionsResponse> => {
  return romanApi.get<useTransactionsResponse>(url);
};

export const useTransactions = (userId: string, handleError: (err: RomanApiError) => void) => {
  const { data, isLoading, error } = useSWR<useTransactionsResponse, RomanApiError>(
    userId ? `/transactions/${userId}` : null,
    fetcher,
  );

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);

  return {
    data: data?.transactions,
    isLoading,
  };
};
